import SERVICE_URLS from '@/api/service.url'

const MAP_INFO_ID = -1

const mapDevices = {
  data () {
    return {
      customSearchByBizType: 'xj',
      searchCoverParams: {
        mapInfoId: MAP_INFO_ID,
        typeLabels: [],
        type: undefined
      },
      dbDevicesData: []
    }
  },
  created () {
    this.searchCoverList()
  },
  methods: {
    /**
     * 查询覆盖物列表
     */
    searchCoverList () {
      this.searchCoverParams.type = this.customSearchByBizType
      this.$http(this, {
        url: SERVICE_URLS.equipment2.monitorPointApi.customSearchByBiz,
        noTips: true,
        data: this.searchCoverParams,
        success: (data) => {
          this.dbDevicesData = data.body
        },
        error: () => {
          console.error('Load cover list error.')
        }
      })
    }
  }
}

export default mapDevices
