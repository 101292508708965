<template>
  <a-spin :spinning="spinShow">
    <a-descriptions v-if="xunjianDetail.name" :column="3">
      <div slot="title">
        <a-row>
          <a-col :span="22">基础信息</a-col>
          <a-col :span="2">
            <a :style="{ marginLeft: '8px', fontSize: '12px' }" @click="toggle">
              {{expand?'收起':'展开'}}
              <a-icon :type="expand ? 'up' : 'down'"/>
            </a>
          </a-col>
        </a-row>
      </div>
      <template v-if="expand">
        <a-descriptions-item label="巡检人">{{ xunjianDetail.name }}</a-descriptions-item>
        <a-descriptions-item label="所属部门">{{ xunjianDetail.deptName }}</a-descriptions-item>
        <a-descriptions-item label="巡检结果" :span="2" v-if="!xunjianDetail.online">
          <a-tag v-if="xunjianDetail.result === 'NORMAL'" color="blue">正常</a-tag>
          <a-tag v-if="xunjianDetail.result === 'ABNORMAL'" color="red">异常</a-tag>
        </a-descriptions-item>
        <a-descriptions-item label="巡检时长">{{ xunjianDetail.spendTime }}</a-descriptions-item>
        <a-descriptions-item label="巡检里程">{{ xunjianDetail.mileage }}&nbsp;km</a-descriptions-item>
        <!--        <a-descriptions-item label="正常设备">{{ xunjianDetail.normalAmount }}</a-descriptions-item>-->
        <!--        <a-descriptions-item label="异常设备"-->
        <!--        ><span :class="[xunjianDetail.abnormalAmount > 0 ? 'text-color-danger' : '']">{{-->
        <!--          xunjianDetail.abnormalAmount === 0 ? '0' : xunjianDetail.abnormalAmount-->
        <!--        }}</span>-->
        <!--        </a-descriptions-item>-->
        <a-descriptions-item label="巡检时间" :span="2">
          <span>{{ moment(xunjianDetail.startTime).format(formatStr) }}</span>
          <span>&nbsp;~&nbsp;</span>
          <span v-if="xunjianDetail.online">至今</span>
          <span v-if="!xunjianDetail.online">{{ moment(xunjianDetail.endTime).format(formatStr) }}</span>
        </a-descriptions-item>
        <a-descriptions-item :span="2" label="开始位置">
          {{ xunjianDetail.startLocation }}&nbsp;
        </a-descriptions-item>
        <a-descriptions-item :span="2" label="结束位置">
          &nbsp;{{ xunjianDetail.endLocation }}
        </a-descriptions-item>
      </template>
    </a-descriptions>
    <a-descriptions :column="2" title="巡检点" v-if="xunjianDetail.name" class="p-b-12 p-t-24">
      <div>
        <template v-for="(item, index) in xunjianDetail.recordNodes">
          <a-tag
            :color="item.result === 'NORMAL' ? 'blue' : 'red'"
            @click="recordNodeClickHandle(item)"
            style="font-size: 14px;"
            class="m-r-none cursor-pointer m-b-12"
          >{{ item.node.name }}
          </a-tag
          >
          <span v-if="index < xunjianDetail.recordNodes.length - 1" class="p-l-r-16" style="color: rgba(0, 0, 0, 0.25);"
          ><a-icon type="right"
          /></span>
        </template>
        <span v-if="xunjianDetail.recordNodes && xunjianDetail.recordNodes.length === 0">暂无巡检点信息</span>
      </div>
    </a-descriptions>
    <a-descriptions :column="2" v-if="xunjianDetail.name">
      <div slot="title">
        <span>巡检轨迹</span>
        <a-button class="m-l-12" size="small" type="primary" icon="sync" @click="buildTrack">轨迹回放</a-button>
      </div>
    </a-descriptions>
    <div :id="mapContainerId" style="width: 100%;" :style="{height:styleMapHeight+'px'}"></div>
    <a-modal :title="currentItem.node.name" :maskClosable="false" :footer="null" v-model="recordNodeModal">
      <xunjian-record-node-detail ref="recordNodeDetailRef"></xunjian-record-node-detail>
    </a-modal>
    <div class="hide">{{ mapStyle }}</div>
  </a-spin>
</template>

<script>
  import SERVICE_URLS from '@/api/service.url'

  import moment from 'moment'
  import UUID from '../../../utils/UUID'
  import { lazyAMapApiLoaderInstance } from 'vue-amap'
  import '../../../core/amap'

  import xunjianPassed from './mixins/xunjian-passed'
  import mapStyle from './mixins/map-style'

  import XunjianRecordNodeDetail from './XunjianRecordNodeDetail'

  export default {
    name: 'XunjianPassed',
    props: {
      formItem: {
        default: () => {
          return {}
        }
      },
      mapHeight: {
        type: [Number, String],
        default: 300
      }
    },
    mixins: [xunjianPassed, mapStyle],
    components: { XunjianRecordNodeDetail },
    data () {
      return {
        expand: true,
        formatStr: 'YYYY/MM/DD HH:mm:ss',
        mapContainerId: new UUID().id,
        map: undefined,
        currentItem: { node: '' },
        recordNodeModal: false,
        xunjianDetail: this.formItem,
        spinShow: false,
        styleMapHeight: 300
      }
    },
    watch: {
      formItem (val) {
        this.xunjianDetail = val
      }
    },
    methods: {
      toggle () {
        this.expand = !this.expand
        if (this.expand) {
          this.styleMapHeight = 300
        } else {
          this.styleMapHeight = 600
        }
      },
      moment,
      /**
       * 加载巡检详情数据
       */
      loadData (id) {
        this.clearTrack()
        this.$http(this, {
          url: SERVICE_URLS.xj.xjRecord.findRecordDetail,
          data: {
            id: id
          },
          loading: 'spinShow',
          noTips: true,
          success: (data) => {
            this.xunjianDetail = data.body
            this.init()
          }
        })
      },
      init () {
        if (!this.map) {
          lazyAMapApiLoaderInstance.load().then(() => {
            this.map = new AMap.Map(this.mapContainerId, {
              mapStyle: 'amap://styles/normal',
              resizeEnable: true,
              center: [106.230909, 38.487193],
              zoom: 11
            })
            this.buildMapOtherLayer()
            this.serTrackInfo()
            this.buildTrack()
            var me = this
            this.map.on('mouseout', function () {
              if (me.removeOverMarkTips) {
                me.removeOverMarkTips()
              }
            })
          })
        } else {
          this.serTrackInfo()
          this.buildTrack()
        }
      },
      recordNodeClickHandle (item) {
        this.currentItem = item
        this.recordNodeModal = true
        this.$nextTick(() => {
          this.$refs.recordNodeDetailRef.loadData(item)
        })
      },
      serTrackInfo () {
        // 经纬度数组
        this.track = this.xunjianDetail.track
        // 在线状态
        this.online = this.xunjianDetail.online
        // 距离
        this.mileage = this.xunjianDetail.mileage
      }
    }
  }
</script>
